import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Route, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/auth/login/login.component';
import { BreadcrumbsEnum } from './modules/shared/models/breadcrumbs.enum';
import { LogonPageComponent } from './modules/auth/pages/logon-page/logon-page.component';
import {
    ForgottenPasswordPageComponent,
} from './modules/auth/pages/forgotten-password-page/forgotten-password-page.component';
import { ResetPasswordPageComponent } from './modules/auth/pages/reset-password/reset-password-page.component';
import { LoginCodePageComponent } from './modules/auth/pages/login-page/login-code-page/login-code-page.component';
import { ShareFilePageComponent } from './modules/auth/pages/share-file/share-file-page.component';
import { ExamplesComponent } from './components/examples/examples.component';

const routes: Route[] = [
    {
        path: 'crm',
        data: { breadcrumb: BreadcrumbsEnum.Crm },
        loadChildren: () =>
            import('./modules/crm/crm.module').then((m) => m.CrmModule),
    },
    {
        path: 'content',
        data: { breadcrumb: { alias: BreadcrumbsEnum.Content } },
        loadChildren: () =>
            import('./modules/content/content.module').then(
                (m) => m.ContentModule,
            ),
    },
    {
        path: 'databoxes',
        data: { breadcrumb: BreadcrumbsEnum.DataBoxes },
        loadChildren: () =>
            import('./modules/databoxes/databoxes.module').then(
                (m) => m.DataboxesModule,
            ),
    },
    {
        path: 'embed/databoxes',
        data: { breadcrumb: BreadcrumbsEnum.DataBoxes },
        loadChildren: () =>
            import('./modules/databoxes/databoxes.module').then(
                (m) => m.DataboxesModule,
            ),
    },
    {
        path: 'login',
        data: { breadcrumb: { skip: true } },
        component: LoginComponent,
    },
    {
        path: 'login-code',
        data: { breadcrumb: { skip: true } },
        component: LoginCodePageComponent,
    },
    {
        path: 'logon',
        data: { breadcrumb: { skip: true } },
        component: LogonPageComponent,
    },
    {
        path: 'forgotten-password',
        data: { breadcrumb: { skip: true } },
        component: ForgottenPasswordPageComponent,
    },
    {
        path: 'reset-password',
        data: { breadcrumb: { skip: true } },
        component: ResetPasswordPageComponent,
    },
    {
        path: 'share-file',
        data: { breadcrumb: { skip: true } },
        component: ShareFilePageComponent,
    },
    {
        path: 'examples',
        data: { breadcrumb: { skip: true } },
        component: ExamplesComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            paramsInheritanceStrategy: 'always',
            scrollPositionRestoration: 'enabled',
        }),
        BrowserAnimationsModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
