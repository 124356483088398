<p-tabView>
    <p-tabPanel header="Buttons">
        <div class="flex-column gap">
            <div>Severity</div>
            <div class="flex gap">
                <app-button-v2 [icon]="'pi-upload'"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" severity="warning"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" severity="secondary"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" severity="info"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" severity="success"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" severity="danger"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" severity="help"></app-button-v2>
            </div>

            <div>Sizes</div>
            <div class="flex gap">
                <app-button-v2 [icon]="'pi-upload'" size="small"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" size="large"></app-button-v2>
            </div>

            <div>Label</div>
            <div class="flex gap">
                <app-button-v2 [icon]="'pi-upload'"></app-button-v2>
                <app-button-v2 label="Button"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" label="Button"></app-button-v2>
            </div>

            <div>SVG</div>
            <div class="flex gap">
                <app-button-v2 [svgIcon]="'transportCar'"></app-button-v2>
                <app-button-v2 label="Button"></app-button-v2>
                <app-button-v2 [svgIcon]="'transportCar'" label="Button"></app-button-v2>
            </div>

            <div>Loading</div>
            <div class="flex gap">
                <app-button-v2 [svgIcon]="'transportCar'" [loading]="true"></app-button-v2>
                <app-button-v2 label="Button" [loading]="true"></app-button-v2>
                <app-button-v2 [svgIcon]="'transportCar'" label="Button" [loading]="true"></app-button-v2>
            </div>

            <div>Icon position</div>
            <div class="flex gap">
                <app-button-v2 [icon]="'pi-upload'" label="Button"></app-button-v2>
                <app-button-v2 [icon]="'pi-upload'" label="Button" [iconPosition]="'right'"></app-button-v2>
                <app-button-v2 [svgIcon]="'transportCar'" label="Button"></app-button-v2>
                <app-button-v2 [svgIcon]="'transportCar'" label="Button" [iconPosition]="'right'"></app-button-v2>
            </div>

            <div>Special button types</div>
            <div class="flex gap">
                <app-cancel-button-v2></app-cancel-button-v2>
                <app-continue-button-v2></app-continue-button-v2>
                <app-delete-button-v2></app-delete-button-v2>
                <app-return-button-v2></app-return-button-v2>
                <app-save-button-v2 (clickEvent)="saveClick($event)"></app-save-button-v2>
                <app-save-button-v2 (clickEvent)="saveOnEnterAndClick($event)" [submitOnEnter]="true"
                    label="Uložit a Submit"></app-save-button-v2>
                <app-link-button-v2 label="Link button"></app-link-button-v2>
            </div>

            <div>Delete button types</div>
            <div class="flex gap">
                <app-delete-button-v2 confirmationType="none" label="No Confirmation"
                    (clickEvent)="withoutConfirmation()"></app-delete-button-v2>
                <app-delete-button-v2 label="Popup"
                    (clickEvent)="confirmPopup()"></app-delete-button-v2>
                <app-delete-button-v2 label="Custom Popup" [confirmationMessageConfig]="confInfo"
                    (clickEvent)="confirmPopup()"></app-delete-button-v2>
                <app-delete-button-v2 confirmationType="dialog" label="Dialog"
                    (clickEvent)="confirmDialog()"></app-delete-button-v2>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Action Buttons component">
        <div class="flex-column gap">
            <div>Page</div>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer rutrum, orci vestibulum ullamcorper
                ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet enim. Maecenas sollicitudin.
                Donec quis nibh at felis congue commodo. Fusce dui leo, imperdiet in, aliquam sit amet, feugiat eu,
                orci. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Aliquam ornare wisi eu metus.
                Etiam quis quam. Etiam dui sem, fermentum vitae, sagittis id, malesuada in, quam. Duis bibendum, lectus
                ut viverra rhoncus, dolor nunc faucibus libero, eget facilisis enim ipsum id lacus. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur ligula sapien, pulvinar a
                vestibulum quis, facilisis vel sapien. Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos hymenaeos. Donec vitae arcu. Mauris tincidunt sem sed arcu. Quisque porta.</p>

            <p>Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Fusce tellus. Curabitur ligula sapien,
                pulvinar a vestibulum quis, facilisis vel sapien. In convallis. Phasellus faucibus molestie nisl. Sed
                vel lectus. Donec odio tempus molestie, porttitor ut, iaculis quis, sem. Class aptent taciti sociosqu ad
                litora torquent per conubia nostra, per inceptos hymenaeos. Aliquam erat volutpat. Proin pede metus,
                vulputate nec, fermentum fringilla, vehicula vitae, justo. Nullam dapibus fermentum ipsum. Integer
                vulputate sem a nibh rutrum consequat. Aliquam erat volutpat. Aliquam id dolor. In rutrum.</p>

            <p>Curabitur sagittis hendrerit ante. Proin mattis lacinia justo. Nam quis nulla. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Cum sociis natoque penatibus et
                magnis dis parturient montes, nascetur ridiculus mus. Curabitur ligula sapien, pulvinar a vestibulum
                quis, facilisis vel sapien. Suspendisse nisl. Nulla non lectus sed nisl molestie malesuada. Nulla
                accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Sed
                ac dolor sit amet purus malesuada congue. Praesent id justo in neque elementum ultrices. Nam sed tellus
                id magna elementum tincidunt. Duis pulvinar. Duis sapien nunc, commodo et, interdum suscipit,
                sollicitudin et, dolor. Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque eu. Vivamus
                porttitor turpis ac leo. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est. Aliquam
                erat volutpat. In sem justo, commodo ut, suscipit at, pharetra vitae, orci.</p>

            <app-action-buttons>
                <app-delete-button-v2></app-delete-button-v2>
            </app-action-buttons>

            <div>Dialog</div>
            <app-button-v2 label="Open dialog" (clickEvent)="actionButtonsOpenDialog()"></app-button-v2>
        </div>

        <p-dialog
            [(visible)]="actionButtonsDialogVisible"
            [header]="'Testovaci dialog'"
            styleClass="p-dialog-width-30"
        >
            <p>Curabitur sagittis hendrerit ante. Proin mattis lacinia justo. Nam quis nulla. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Cum sociis natoque penatibus et
                magnis dis parturient montes, nascetur ridiculus mus. Curabitur ligula sapien, pulvinar a vestibulum
                quis, facilisis vel sapien. Suspendisse nisl. Nulla non lectus sed nisl molestie malesuada. Nulla
                accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Sed
                ac dolor sit amet purus malesuada congue. Praesent id justo in neque elementum ultrices. Nam sed tellus
                id magna elementum tincidunt. Duis pulvinar. Duis sapien nunc, commodo et, interdum suscipit,
                sollicitudin et, dolor. Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque eu. Vivamus
                porttitor turpis ac leo. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est. Aliquam
                erat volutpat. In sem justo, commodo ut, suscipit at, pharetra vitae, orci.</p>

            <ng-template pTemplate="footer">
                <app-action-buttons>
                    <app-delete-button-v2></app-delete-button-v2>
                </app-action-buttons>
            </ng-template>
        </p-dialog>
    </p-tabPanel>
</p-tabView>