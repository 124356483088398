import { Component, input } from '@angular/core';
import { ButtonV2Component } from './button-v2.component';
import { Button } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { PrimeTemplate } from 'primeng/api';

@Component({
    selector: 'app-continue-button-v2',
    templateUrl: 'button-v2.component.html',
    imports: [Button, TranslateModule, SvgIconComponent, PrimeTemplate],
    standalone: true,
})
export class ContinueButtonV2Component extends ButtonV2Component {

    override label = input<string | null>('inputs.continue');
    override icon = input<string | null>('pi-arrow-right');

}
