import { Component, inject, input } from '@angular/core';
import { ButtonV2Component } from './button-v2.component';
import { Location } from '@angular/common';
import { SeverityType } from './button-v2.types';
import { Button } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { PrimeTemplate } from 'primeng/api';

@Component({
    selector: 'app-return-button-v2',
    templateUrl: 'button-v2.component.html',
    imports: [Button, TranslateModule, SvgIconComponent, PrimeTemplate],
    standalone: true,
})
export class ReturnButtonV2Component extends ButtonV2Component {
    private readonly location = inject(Location);

    override label = input<string | null>('inputs.cancel');
    override icon = input<string | null>('pi-times');
    override severity = input<SeverityType>('secondary');
    override styleClass = input<string | null>('p-button-outlined');

    constructor() {
        super();

        this.clickEvent.subscribe(() => this.location.back());
    }
}
