import { Component, inject, input } from '@angular/core';
import { ButtonV2Component } from './button-v2.component';
import { ConfirmationType, SeverityType } from './button-v2.types';
import { Button } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { PrimeTemplate } from 'primeng/api';
import {
    ConfirmationWrapperService,
    DEFAULT_CONFIRMATION_DELETE_INPUT,
} from '../../../services/wrappers/confirmation-wrapper.service';
import { Confirmation } from 'primeng/api/confirmation';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
    selector: 'app-delete-button-v2',
    templateUrl: 'button-v2.component.html',
    imports: [Button, TranslateModule, SvgIconComponent, PrimeTemplate, ConfirmPopupModule, ConfirmDialogModule],
    providers: [ConfirmationWrapperService],
    standalone: true,
})
export class DeleteButtonV2Component extends ButtonV2Component {
    private readonly confirmationService = inject(ConfirmationWrapperService);

    override label = input<string | null>('inputs.delete');
    override icon = input<string | null>('pi-trash');
    override severity = input<SeverityType>('danger');

    confirmationType = input<ConfirmationType>('popup');
    confirmationMessageConfig = input<Confirmation>(DEFAULT_CONFIRMATION_DELETE_INPUT);

    override async onClick(event: Event) {
        switch (this.confirmationType()) {
            case 'popup':
                if (await this.confirmationService.confirmDelete(event.target as EventTarget, 'confirmPopup', this.confirmationMessageConfig())) {
                    this.clickEvent.emit(event);
                }
                break;
            case 'dialog':
                if (await this.confirmationService.confirmDelete(event.target as EventTarget, 'confirmDialog', this.confirmationMessageConfig())) {
                    this.clickEvent.emit(event);
                }
                break;
            default:
                this.clickEvent.emit(event);
        }
    }

}
