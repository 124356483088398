<p-confirmDialog
    key="confirmDialog"
    position="top"
    acceptLabel="{{ 'yes' | translate }}"
    rejectLabel="{{ 'no' | translate }}"
></p-confirmDialog>
<!--<p-confirmPopup-->
<!--    key="confirmPopup"-->
<!--&gt;</p-confirmPopup>-->
<!--TODO tady se ten popup nanestesti nechyta :/ -->
<app-layout *ngIf="displayLayout$ | async; else noLayout" [backendUrl]="backendUrl">
    <router-outlet main-panel></router-outlet>
</app-layout>
<ng-template #noLayout>
    <router-outlet></router-outlet>
    <app-config></app-config>
</ng-template>
