import { Injectable, signal, WritableSignal } from '@angular/core';
import { localStorageOrganizationIdKey } from '../utils/local-storage.utils';

@Injectable({
    providedIn: 'root',
})
export class OrganizationGlobalStore {

    public selectedId: WritableSignal<number> = signal(
        Number(localStorage.getItem(localStorageOrganizationIdKey) || 0),
    );

}
