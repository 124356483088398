import { Component, HostListener, input } from '@angular/core';
import { ButtonV2Component } from './button-v2.component';
import { Button } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { PrimeTemplate } from 'primeng/api';

@Component({
    selector: 'app-save-button-v2',
    templateUrl: 'button-v2.component.html',
    imports: [Button, TranslateModule, SvgIconComponent, PrimeTemplate],
    standalone: true,
})
export class SaveButtonV2Component extends ButtonV2Component {

    override label = input<string | null>('inputs.save');
    override icon = input<string | null>('pi-check');

    submitOnEnter = input<boolean>(false);

    @HostListener('window:keydown.enter', ['$event'])
    public submitted(event: any) {
        event.stopPropagation();
        if (this.submitOnEnter()) {
            this.clickEvent.emit(event);
        }
    }

}
