import { Component } from '@angular/core';
import { ButtonV2Component } from '../kit/button-v2/button-v2.component';
import { CancelButtonV2Component } from '../kit/button-v2/cancel-button-v2.component';
import { ContinueButtonV2Component } from '../kit/button-v2/continue-button-v2.component';
import { DeleteButtonV2Component } from '../kit/button-v2/delete-button-v2.component';
import { ReturnButtonV2Component } from '../kit/button-v2/return-button-v2.component';
import { SaveButtonV2Component } from '../kit/button-v2/save-button-v2.component';
import { Confirmation } from 'primeng/api/confirmation';
import { LinkButtonV2Component } from '../kit/button-v2/link-button-v2.component';
import { TabViewModule } from 'primeng/tabview';
import { ActionButtonsComponent } from '../kit/action-buttons/action-buttons.component';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from '../../modules/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-examples',
    templateUrl: 'examples.component.html',
    standalone: true,
    imports: [
        ButtonV2Component,
        CancelButtonV2Component,
        ContinueButtonV2Component,
        DeleteButtonV2Component,
        ReturnButtonV2Component,
        SaveButtonV2Component,
        LinkButtonV2Component,
        TabViewModule,
        ActionButtonsComponent,
        DialogModule,
        SharedModule,
        TranslateModule,
    ],
})
export class ExamplesComponent {
    confInfo = <Confirmation>{
        message: 'confirmDialog.message',
        header: 'confirmDialog.header',
        icon: 'pi pi-trash',
        acceptLabel: 'inputs.yes',
        acceptIcon: 'pi pi-trash',
        acceptButtonStyleClass: 'p-button-help',
        rejectLabel: 'inputs.no',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-secondary',
    };

    actionButtonsDialogVisible: boolean = false;

    constructor() {
    }

    withoutConfirmation() {
        console.log('without confirmation');
    }

    confirmPopup() {
        console.log('popup');
    }

    confirmDialog() {
        console.log('dialog');
    }

    onClick(event: Event) {
        console.log('click event', event);
    }

    saveClick(event: Event) {
        console.log('save event', event);
    }

    saveOnEnterAndClick(event: Event) {
        console.log('submit and save event', event);
    }

    actionButtonsOpenDialog() {
        this.actionButtonsDialogVisible = true;
    }
}
