import { serviceFrag } from './service.types';
import { insuranceCompanyFrag } from './insurance-company.types';
import { personFrag } from './person.types';
import { voucherGridItemFrag } from './voucher.types';
import { performedProcedureGridItemFrag, workGridItemFrag } from './work.types';
import { anamnesisFrag } from './anamnesis.types';

export const billingGridItemFrag = `
    id
    sentToInsuranceCompany
    sendingPostponed
    years
    months
    invoiceNumbers
    insuranceTypes
    invoiceDates
    invoiceDueDates
    numberOfBatches
    priceForBatches
    invoicedAmount
    fdavka
    kdavka
`;

export const invoiceFrag = `
    id
    invoiceNumber
    year
    month
    serviceICZ
    maximalMonthlyPrepaymentLimit
    invoiceDate
    invoiceDueDate
    invoiceSentDate
    service {
        ${serviceFrag}
    }
    insuranceCompany {
        ${insuranceCompanyFrag}
    }
    insuranceType
    unprocessedBillingId
    billingId
`;

export const invoiceGridItemFrag = `
    id
    invoiceNumber
    year
    month
    serviceICZ
    maximalMonthlyPrepaymentLimit
    insuranceType
    invoiceDate
    invoiceDueDate
    invoiceSentDate
    numberOfBatches
    priceForBatches
    invoicedAmount
    insuranceAbbr
    serviceId
    insuranceCompanyId
    unprocessedBillingId
    billingId
`;

export const billingBatchFrag = `
    id
    batchNumber
    batchType
    batchNature
    codeOfTerritorialWorkplace
    dataInterfaceVersion
    paymentType
    invoice {
        ${invoiceFrag}
    }
    fixedPriceForDocuments
`;

export const billingBatchGridItemFrag = `
    id
    invoiceId
    batchNumber
    batchType
    batchNature
    year
    month
    insuranceAbbr
    insuranceType
    numberOfDocuments
    documentNumbersStart
    documentNumbersEnd
    pointsForDocuments
    priceForDocuments
`;

export const billingDocumentFrag = `
    id
    documentNumber
    serialNumber
    billingBatch {
        ${billingBatchFrag}
    }
    priceTotal
    fixedPrice
    billingStatus
    folderId
    person {
        ${personFrag}
    }
    voucherId
    voucher {
        ${voucherGridItemFrag}
    }
    anamnesisId
    anamnesis {
        ${anamnesisFrag}
    }
    pointsTotal
    serviceICZ
    serviceVariableSymbol
    userId
    user {
        id
        name
        surname
        titleBeforeName
        titleAfterName
    }
    workplace
    transportType
    transportPrice
    flatRatePerMonth
    requesterICPVehicle
`;

export const billingDocumentGridItemFrag = `
    id
    documentNumber
    serialNumber
    dateFrom
    dateTo
    priceTotal
    billingStatus
    insuranceAbbr
    insuranceType
    folderId
    person {
        ${personFrag}
    }
    voucherId
    voucher {
        ${voucherGridItemFrag}
    }
    anamnesisId
    anamnesis {
        ${anamnesisFrag}
    }
    procedures
    applications
    pointsTotal
    userId
    user {
        id
        name
        surname
        titleBeforeName
        titleAfterName
    }
    billedVisits
    traveledDistance
`;

export const billingErrorFrag = `
    id
    name
    message
    enableSolve
    relatedWorks {
        ${workGridItemFrag}
    }
`;

export const billingErrorsFrag = `
    unbilledWork
    anyWorksIncluded
    errors {
        ${billingErrorFrag}
    }
    missingPaymentSettings {
        insuranceAbbr
        insuranceType
    }
`;

export const billingFrag = `
    id
`;

export const billingFilesFrag = `
    billingIds
    fileName
    base64
`;

export const billedProcedureGridItemFrag = `
    id
    performedProcedureId
    billingDocumentId
    billingDocumentNumber
    billingStatus
    billingReasonForCancellation
    billingPrice
    performedProcedure {
        ${performedProcedureGridItemFrag}
    }
`;

export const billedVisitGridItemFrag = `
    id
    workId
    billingDocumentId
    billingDocumentNumber
    billingStatus
    billingReasonForCancellation
    work {
        ${workGridItemFrag}
    }
`;
