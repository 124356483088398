import { ConfirmationService } from 'primeng/api';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Confirmation } from 'primeng/api/confirmation';

export const DEFAULT_CONFIRMATION_DELETE_INPUT = <Confirmation>{
    message: 'confirmDialog.message',
    header: 'confirmDialog.header',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: 'confirmDialog.confirmButton',
    acceptIcon: 'pi pi-trash',
    acceptButtonStyleClass: 'p-button-danger',
    rejectLabel: 'inputs.dismiss',
    rejectIcon: 'pi pi-times',
    rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
};

@Injectable({ providedIn: 'root' })
export class ConfirmationWrapperService {

    constructor(public confirmationService: ConfirmationService, private readonly translate: TranslateService) {
    }

    confirmDelete(
        target: EventTarget,
        key: string = 'confirmPopup',
        input: Confirmation = DEFAULT_CONFIRMATION_DELETE_INPUT,
    ): Promise<boolean> {
        return new Promise(resolve => {
            this.confirmationService.confirm({
                message: this.translate.instant(input.message || ''),
                header: this.translate.instant(input.header || ''),
                icon: input.icon,
                key,
                target,
                acceptLabel: this.translate.instant(input.acceptLabel || ''),
                acceptIcon: input.acceptIcon,
                acceptButtonStyleClass: input.acceptButtonStyleClass,
                rejectLabel: this.translate.instant(input.rejectLabel || ''),
                rejectIcon: input.rejectIcon,
                rejectButtonStyleClass: input.rejectButtonStyleClass,
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                },
            });
        });
    }
}