import { personAppointmentFrag } from './person.types';
import { serviceGridItemFrag, serviceMedicalProcedureGridItemFrag } from './service.types';
import { serviceUserFrag } from './user.types';

export const schedulerVoucherPrescribedMedicalProceduresFrag = `
    id
    code
    voucherId
    voucher {
        id
        type
        sequenceNumber
        doctor {
            ${serviceUserFrag}
        }
    }
    service {
        id
        name
    }
    serviceMedicalProcedureId
    serviceMedicalProcedure {
        ${serviceMedicalProcedureGridItemFrag}
    }
    canBeBilled
    canBeBilledWeekend
    canBeBilledNight
    continuousOperation
    courseDescription
    recordTemplate
    usualNumberOfApplications
    frequency
`;

export const schedulerAppointmentFrag = `
    id
    startTime
    endTime
    location
    isAllDay
    isBlock
    isReadonly
    recurrenceRule
    recurrenceException
    recurrenceId
    startTimezone
    endTimezone
    description
    followingId
    systolicPressure
    diastolicPressure
    pulse
    breath
    glycemia
    spo2
    userId
    personId
    person {
        ${personAppointmentFrag}
    }
    medications {
        id
        code
        dose
        folderMedicationId
        serviceId
        medication {
            name
            code
        }
        service {
            id
            name
            timeRanges {
                order
                active
                from
                to
            }
        }
    }
    procedures {
        id
        code
        applications
        serviceMedicalProcedureId
        voucherPrescribedMedicalProcedureId
        serviceId
        service {
            name
        }
        voucherPrescribedMedicalProcedure {
            ${schedulerVoucherPrescribedMedicalProceduresFrag}
        }
    }
    services {
        workId
        serviceId
        folder {
            id
        }
    }
    service {
        ${serviceGridItemFrag}
    }
`;
