import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentStoreFactory } from '../../../shared/store/common/component-store.factory';
import { OrganizationObject, OrganizationsGridObject, ServiceGridObject } from '@it2go/types';
import {
    setCurrentActiveIntegrations,
    setCurrentOrganizationId,
    setOrganization,
    setOrganizations,
    setServices,
} from '../../../../store/global/global.actions';
import { Observable, zip } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { FilterBuilder } from '../../../content/helper/filter.builder';
import {
    OrganizationSettingsGridObject,
    OrganizationSettingsObject,
} from '@libs/types/src/organization/object-type/organization/organization-settings.object';
import { OrganizationService } from '../../../../services/organization.service';
import { ServiceService } from '../../../../services/service.service';
import { SettingsService } from 'packages/client/src/app/services/settings.service';
import { GlobalStore } from '../../../../store/global.global-store';
import { localStorageOrganizationIdKey } from '../../../../utils/local-storage.utils';

interface State {
    selectedOrganizationId: number | null,
    organization: OrganizationObject | null,
    organizations: OrganizationsGridObject | null,
    services: ServiceGridObject | null,
}

const defaultState: State = {
    selectedOrganizationId: null,
    organization: null,
    organizations: null,
    services: null,
};

@Injectable()
export class OrganizationSelectStore extends ComponentStoreFactory<State> {

    setOrganizationId = this.effect((input$: Observable<number>) =>
        input$.pipe(
            tap({
                next: (organizationId) => {
                    localStorage.setItem(localStorageOrganizationIdKey, String(organizationId));
                    this.globalStoreV2.organizationStore.selectedId.set(organizationId);
                    this.globalStore!.dispatch(setCurrentOrganizationId({ organizationId }));
                    this.api.getServices(FilterBuilder.singlePage({ organizationId }));
                    this.api.getOrganization({ id: organizationId, validAt: null, centerId: null });
                    this.api.getOrganizationSettings(FilterBuilder.singlePage({ organizationId }));
                },
            }),
        ),
    );

    constructor(
        settingsService: SettingsService,
        serviceSvc: ServiceService,
        orgSvc: OrganizationService,
        store: Store,
        private readonly globalStoreV2: GlobalStore,
    ) {
        super(defaultState, store, orgSvc, serviceSvc, settingsService);
        this.subs.push(
            this.data.organization$.subscribe((organization) => {
                this.globalStore!.dispatch(setOrganization({ organization }));
            }),
            this.data.organizations$.pipe(
                withLatestFrom(this.data.selectedOrganizationId$),
            ).subscribe(([grid, selectedOrganizationId]) => {
                this.globalStore!.dispatch(setOrganizations({ organizations: grid?.['items'] || [] }));
                if(selectedOrganizationId === null) {
                    const firstId = grid?.['items']?.[0]?.id || 0;
                    this.globalStore!.dispatch(setCurrentOrganizationId({ organizationId: firstId }));
                    this.api.getOrganizationSettings(FilterBuilder.singlePage({ organizationId: firstId }));
                }
            }),
            this.data.services$.subscribe((grid) => {
                this.globalStore!.dispatch(setServices({ services: grid?.['items'] || [] }));
            }),
            this.apiSuccess.getOrganizationSettings.subscribe((it: OrganizationSettingsGridObject) => {
                this.globalStore!.dispatch(setCurrentActiveIntegrations({
                    activeIntegrations: (it?.['items'] || [])
                        .filter((i: OrganizationSettingsObject) => i.active)
                        .reduce((acc: Record<string, { data: string }>, i: OrganizationSettingsObject) => {
                            acc[i.key] = i;
                            return acc;
                        }, {}),
                }));
            }),
            this.apiFailure.getOrganizationSettings.subscribe(() => {
                this.globalStore!.dispatch(setCurrentActiveIntegrations({ activeIntegrations: {} }));
            }),
        );

        this.doOnActions(
            async () => this.api.getOrganizationSettings(FilterBuilder.singlePage({ organizationId: await this.data.selectedOrganizationId() })),
            'getOrCreateOrganizationSetting',
            'removeOrganizationSetting',
            'updateOrganizationSetting',
        );

        this.doOnActions(
            () => this.api.getOrganizations(FilterBuilder.singlePage({})),
            'createOrganization',
            'updateOrganization',
            'deleteOrganization',
        );

        this.doOnActions(
            async () => this.api.getServices(FilterBuilder.singlePage({ organizationId: await this.data.selectedOrganizationId() })),
            'createService',
            'updateService',
            'deleteService',
            'deleteWorkplace',
            'updateWorkplace',
            'createWorkplace',
        );
    }

}
