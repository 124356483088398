import { Component, inject, input, output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PrimaryButtonType, SecondaryButtonType } from './action-buttons.types';
import { CancelButtonV2Component } from '../button-v2/cancel-button-v2.component';
import { SaveButtonV2Component } from '../button-v2/save-button-v2.component';
import { ReturnButtonV2Component } from '../button-v2/return-button-v2.component';
import { ContinueButtonV2Component } from '../button-v2/continue-button-v2.component';

@Component({
    selector: 'app-action-buttons',
    templateUrl: 'action-buttons.component.html',
    imports: [TranslateModule, CancelButtonV2Component, SaveButtonV2Component, ReturnButtonV2Component, ContinueButtonV2Component],
    standalone: true,
})
export class ActionButtonsComponent {
    public readonly translate = inject(TranslateService);

    primaryButtonType = input<PrimaryButtonType>('save');
    secondaryButtonType = input<SecondaryButtonType>('cancel');

    primaryButtonVisibility = input<boolean>(true);
    secondaryButtonVisibility = input<boolean>(true);

    primaryButtonDisability = input<boolean>(false);
    secondaryButtonDisability = input<boolean>(false);

    fullWidth = input<boolean>(false);

    primaryClickEvent = output<Event>();
    secondaryClickEvent = output<Event>();

    onPrimaryClick(event: Event) {
        this.primaryClickEvent.emit(event);
    }

    onSecondaryClick(event: Event) {
        this.secondaryClickEvent.emit(event);
    }
}
