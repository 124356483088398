<p-button
    [label]="label() ? (label()! | translate) : null"
    [icon]="icon() ? 'pi ' + icon() : null"
    [iconPos]="iconPosition()"
    [styleClass]="btnClass()"
    [size]="size()"
    [severity]="severity()"
    [disabled]="disabled()"
    [loading]="loading()"
    [text]="text()"
    [rounded]="rounded()"
    (onClick)="onClick($event)"
>
    @if (svgIcon()) {
        <ng-template pTemplate="icon">
            <svg-icon class="appBtn_svgIcon" [name]="svgIcon()"></svg-icon>
        </ng-template>
    }
</p-button>
