<div class="dialogButtons" [class.dialogButtons-fullWidth]="fullWidth()">
    <div class="flex gap-small align-items-center">
        <ng-content></ng-content>
    </div>

    <div class="flex gap-small align-items-center">
        @if (secondaryButtonVisibility()) {
            @switch (secondaryButtonType()) {
                @case ('cancel') {
                    <app-cancel-button-v2 [disabled]="secondaryButtonDisability()"
                        (clickEvent)="onSecondaryClick($event)"></app-cancel-button-v2>
                }
                @case ('return') {
                    <app-return-button-v2 [disabled]="secondaryButtonDisability()"
                        (clickEvent)="onSecondaryClick($event)"></app-return-button-v2>
                }
            }
        }

        @if (primaryButtonVisibility()) {
            @switch (primaryButtonType()) {
                @case ('save') {
                    <app-save-button-v2 [disabled]="primaryButtonDisability()"
                        (clickEvent)="onPrimaryClick($event)"></app-save-button-v2>
                }
                @case ('continue') {
                    <app-continue-button-v2 [disabled]="primaryButtonDisability()"
                        (clickEvent)="onPrimaryClick($event)"></app-continue-button-v2>
                }
            }
        }
    </div>
</div>