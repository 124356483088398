import { Component, computed, inject, input, output } from '@angular/core';
import { Button } from 'primeng/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SeverityType } from './button-v2.types';
import { SvgIconComponent } from 'angular-svg-icon';
import { PrimeTemplate } from 'primeng/api';

@Component({
    selector: 'app-button-v2',
    templateUrl: 'button-v2.component.html',
    styleUrls: ['button-v2.component.scss'],
    imports: [Button, TranslateModule, SvgIconComponent, PrimeTemplate],
    standalone: true,
})
export class ButtonV2Component {
    public readonly translate = inject(TranslateService);

    label = input<string | null>(null);
    icon = input<string | null>(null);
    svgIcon = input<string | null>(null);
    iconPosition = input<'left' | 'right'>('left');
    size = input<'small' | 'large' | null>(null);
    severity = input<SeverityType | null>(null);
    styleClass = input<string | null>(null);
    disabled = input<boolean>(false);
    loading = input<boolean>(false);
    rounded = input<boolean>(false);
    text = input<boolean>(false);

    clickEvent = output<Event>();

    btnClass = computed(() => `appBtn ${(this.iconPosition() === 'right' ? `appBtn-right` : '')} ${this.styleClass() || ''}`);

    onClick(event: Event) {
        this.clickEvent.emit(event);
    }
}
