import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { Injectable } from '@angular/core';
import {
    ProceduresValidationErrorObject,
} from '@libs/types/src/services/object-type/procedure/procedures.validation-error-object';
import { ProceduresValidationInput } from '@libs/types/src/services/input-type/procedure/procedures.validation-input';
import { procedureValidationFrag } from './types/work.types';

@Injectable()
export class WorkGqlService extends GqlServiceV2 {

    public async validateProcedures(input: ProceduresValidationInput, frag = procedureValidationFrag): Promise<ProceduresValidationErrorObject[]> {
        return this.query(
            `
                query($input: ProceduresValidationInput!) {
                    performedProcedure {
                        validate(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
