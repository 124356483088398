import { Injectable } from '@angular/core';
import { OrganizationGlobalStore } from './organization.global-store';

@Injectable({
    providedIn: 'root',
})
export class GlobalStore {

    constructor(
        public readonly organizationStore: OrganizationGlobalStore,
    ) {
    }

}
